import React, { useState, useEffect, useRef } from 'react';

const Infinite = () => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetched = await fetch('https://hacker-news.firebaseio.com/v0/topstories.json');
      const items = await fetched.json();
      const stories = await Promise.all(items.slice(0, 40).map(id => fetch(`https://hacker-news.firebaseio.com/v0/item/${id}.json`).then(res => res.json())));
      setItems(stories.map(({ title }) => title));
    }
    fetchData();
  }, []);
  useIntersect();
  return (
    <div id="intersect-root">
      {items.map((item => (
        <div>{item}</div>
      )))}
    </div>
  )
}


export default Infinite;

const useIntersect = () => {

  // const intersect = useRef(new window.IntersectionObserver((entries) => console.log(entries)), {})
  useEffect(() => {
    // intersect.observe(document.getElementById('intersect-root'));
    // return () => intersect.disconnect();
  }, []);
}